import { useMemo, useRef, useState } from 'react'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { ExportRowV1, ExportRowV2 } from 'core/remodel/database/exportHandler'
import { Currency, NumberFormat } from 'core/remodel/types/common'
import { AssetType } from 'core/remodel/types/enums'
import { ComparativeNetWorthReport, NetWorthReportDetails } from 'core/remodel/types/reports'
import { defaultPreferences, Preferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { exportDataV1, exportDataV2, exportSummary } from '@/api/CommonService'
import { subtypeOptionMap } from '@/constants/assets'
import { environment } from '@/constants/site'
import { cn } from '@/utils/classnames'
import { formatDate, formatNumber } from '@/utils/formatter'
import i18n from '@/utils/i18n'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Modal,
  Progress,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/base'
import { ExportIcon } from '@/components/icon'

type ColumnType = 'blank' | 'type' | 'subtype' | 'total' | 'new-page'

interface DataExporterProps {
  assetType: AssetType | 'Global'
  className?: string
}

const origin = {
  prod: 'https://app.myassets.com',
  staging: 'https://app.myassets.dev',
  infradev: 'https://app.myplaceart.com',
  development: 'http://localhost:3000'
}[environment]

export const ExportV2Types = [AssetType.Art, AssetType.Belonging, AssetType.OtherCollectables, AssetType.Property]

export default function DataExporter({ assetType, className }: DataExporterProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const taskId = useRef<string>(database!.genAssetId())
  const assetsV1Data = useRef<ExportRowV1[]>([])
  const assetsV2Data = useRef<ExportRowV2[]>([])
  const summaryData = useRef<ComparativeNetWorthReport | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [fileType, setFileType] = useState<'CSV' | 'PDF' | null>(null)
  const [percentage, setPercentage] = useState<number>(NaN)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const isV2Type = useMemo(() => assetType !== 'Global' && ExportV2Types.includes(assetType), [assetType])

  const handleExport = async (type: 'CSV' | 'PDF' = 'PDF') => {
    setIsOpen(true)
    setFileType(type)
    try {
      if (percentage !== 100) {
        setPercentage(0)
        if (assetType === 'Global') {
          summaryData.current = await exportSummary(database!, setPercentage)
        } else {
          if (isV2Type) await exportDataV2(database!, taskId.current, assetType, assetsV2Data.current, setPercentage)
          else await exportDataV1(database!, taskId.current, assetType, assetsV1Data.current, setPercentage)
        }
      }
      if (type === 'CSV') {
        assetType === 'Global'
          ? handleExportSummaryCsv(summaryData.current!, preferences)
          : isV2Type
          ? handleExportAssetsV2Csv(assetsV2Data.current, preferences, assetType)
          : handleExportAssetsV1Csv(assetsV1Data.current, preferences, assetType)
      } else {
        const printElement =
          assetType === 'Global'
            ? formatSummaryPDF(summaryData.current!, preferences)
            : isV2Type
            ? formatAssetsV2PDF(assetsV2Data.current, assetType, preferences)
            : formatAssetsV1PDF(assetsV1Data.current, preferences)
        const printWindow = window.open()
        if (printWindow === null) {
          // window is blocked
          setIsOpen(false)
          return
        }
        printWindow.document.write(printElement)
        printWindow.print()
      }
      setIsOpen(false)
      setFileType(null)
    } catch (e) {
      console.log(e)
      setPercentage(NaN)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setPercentage(NaN)
  }

  return (
    <>
      <Tooltip>
        <TooltipTrigger>
          {
            <DropdownMenu>
              <DropdownMenuTrigger asChild={true}>
                <Button
                  className={cn('h-9 w-9 text-white', className)}
                  variant={'solid'}
                  disabled={isOpen}
                  aria-label={'Export data'}
                  data-testid={'export-data-button'}
                >
                  <ExportIcon />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align={'end'}>
                <DropdownMenuItem onSelect={() => handleExport('CSV')}>{'CSV'}</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => handleExport('PDF')}>{'PDF'}</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          }
        </TooltipTrigger>
        <TooltipContent>{t('Export')}</TooltipContent>
      </Tooltip>

      {isOpen && (
        <Modal className={'max-w-md'} onBackdropClick={handleClose}>
          <Modal.Header>
            <label className={'text-sm font-medium uppercase text-primary'}>{t('ExportTitle', { fileType })}</label>
          </Modal.Header>
          <Modal.Content>
            {!isNaN(percentage!) ? (
              <div className={'flex flex-col items-center justify-center gap-3 text-sm'}>
                <p>{t('ExportDescription')}</p>
                <Progress value={percentage} />
                <p>{`${percentage} %`}</p>

                {percentage !== 100 && (
                  <fieldset className={'flex w-full justify-end gap-2'}>
                    <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={handleClose}>
                      {t('Cancel')}
                    </Button>
                  </fieldset>
                )}
              </div>
            ) : (
              <div className={'text-sm'}>
                {t('ExportFailed')}

                <fieldset className={'mt-4 flex justify-end gap-2'}>
                  <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={handleClose}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    className={'group relative min-w-[130px]'}
                    variant={'solid'}
                    size={'md'}
                    onClick={() => handleExport(fileType ?? 'PDF')}
                  >
                    <span className={'group-disabled:opacity-0'}>{t('TryAgain')}</span>
                  </Button>
                </fieldset>
              </div>
            )}
          </Modal.Content>
        </Modal>
      )}
    </>
  )
}

export const assetTypeReportHeaderMapV1 = {
  id: 'Asset Id',
  createdAt: 'Created On',
  updatedAt: 'Updated On',
  assetType: 'Category',
  subtype: 'Type',
  name: 'Name',
  purchaseDate: 'Purchase Date',
  currency: 'Currency',
  currentValue: 'Current Value',
  archived: 'Archived',
  notes: 'Notes'
}

export const assetTypeReportHeaderMapV2: Record<keyof Omit<ExportRowV2, 'id' | 'notes'>, string> = {
  personalRefNo: 'Field.PersonalRefNo',
  category: 'finances:Field.Category',
  type: 'Field.Type',
  name: 'Name',
  creator: 'Field.Creator',
  numberOfItems: 'Field.NumberOfItems',
  purchaseDate: 'Field.PurchaseDate',
  currency: 'Currency',
  totalCost: 'Field.TotalCost',
  currentValuation: 'Field.CurrentValuation',
  lastUpdated: 'Field.LastUpdated'
}

function formatAssetsV1PDF(data: ExportRowV1[], preferences: Preferences) {
  const csvData = data.map((row) => ({
    ...row,
    assetType: i18n.t(`AssetTypeOptions.${row.assetType}`),
    subtype: i18n.t(`${subtypeOptionMap[row.assetType]}${row.subtype}`, row.subtype),
    createdAt: formatDate(row.createdAt, preferences.dateFormat, preferences.timeZone),
    updatedAt: formatDate(row.updatedAt, preferences.dateFormat, preferences.timeZone),
    currentValue: formatNumber(row.currentValue, preferences.numberFormat),
    purchaseDate: formatDate(row.purchaseDate, preferences.dateFormat, preferences.timeZone),
    notes: row.notes || '-'
  }))

  return `
    <style type="text/css">
      table {
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
        font-family: "Roboto", sans-serif;
      }
      td {
        // overflow-wrap: break-word;
        border: 1px solid black;
        border-collapse: collapse;
      }
      th {
        text-align: start;
        background-color: grey;
        border: 1px solid black;
        border-collapse: collapse;
      }
    </style>
    <img src="${origin}/images/myassets-logo.svg" width="220" height="30" />
    <br />
    <br />
    <table>
      <tr>
        ${Object.values(assetTypeReportHeaderMapV1)
          .map((value) => `<th>${value}</th>`)
          .join('\n')}
      </tr>
      ${csvData
        .map(
          (row) => `
          <tr>
            ${Object.values(row)
              .map((val) => `<td>${val}</td>`)
              .join('\n')}
          </tr>`
        )
        .join('\n')}
    </table> 
  `
}

function formatAssetsV2PDF(data: ExportRowV2[], assetType: AssetType, preferences: Preferences) {
  const csvData = data.map((row) => ({
    ...row,
    personalRefNo: row.personalRefNo || '-',
    category: i18n.t(`AssetTypeOptions.${row.category}`),
    type: i18n.t(`${subtypeOptionMap[row.category]}${row.type}`, row.type),
    currentValuation: formatNumber(row.currentValuation, preferences.numberFormat),
    totalCost: formatNumber(row.totalCost || 0, preferences.numberFormat),
    lastUpdated: formatDate(row.lastUpdated, preferences.dateFormat, preferences.timeZone),
    purchaseDate: formatDate(row.purchaseDate, preferences.dateFormat, preferences.timeZone),
    creator: row.creator || '-',
    numberOfItems: row.numberOfItems || 1
  }))
  const { creator, numberOfItems, ...propertyReportHeaderMap } = assetTypeReportHeaderMapV2
  const headerMap = assetType === AssetType.Property ? propertyReportHeaderMap : assetTypeReportHeaderMapV2
  const CSSList = `
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto">
    <style type="text/css">
      table {
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
        font-family: "Roboto", sans-serif;
      }
      td {
        border: 1px solid black;
        border-collapse: collapse;
      }
      th {
        text-align: start;
        background-color: grey;
        border: 1px solid black;
        border-collapse: collapse;
      }
    </style>`

  const content = `
    ${CSSList}
    <img src="${origin}/images/myassets-logo.svg" width="220" height="30" />
    <br />
    <br />
    <table>
      <tr>
        ${Object.values(headerMap)
          .map((value) => `<th>${i18n.t(value)}</th>`)
          .join('\n')}
      </tr>
      ${csvData
        .map(
          (row) => `
            <tr>
              ${(Object.keys(headerMap) as (keyof ExportRowV2)[]).map((key) => `<td>${row[key]}</td>`).join('\n')}
            </tr>
          `
        )
        .join('\n')}
    </table> 
  `
  return content
}

function handleExportAssetsV1Csv(data: ExportRowV1[], preferences: Preferences, assetType: AssetType) {
  const csvData = data.map((row) => ({
    ...row,
    assetType: i18n.t(`AssetTypeOptions.${row.assetType}`),
    subtype: i18n.t(`${subtypeOptionMap[assetType]}${row.subtype}`, row.subtype),
    createdAt: formatDate(row.createdAt, preferences.dateFormat, preferences.timeZone),
    updatedAt: formatDate(row.updatedAt, preferences.dateFormat, preferences.timeZone),
    currentValue: formatNumber(row.currentValue, preferences.numberFormat),
    purchaseDate: formatDate(row.purchaseDate, preferences.dateFormat, preferences.timeZone),
    notes: row.notes || '-'
  }))
  const columnHeaders = Object.entries(assetTypeReportHeaderMapV1).map(([key, i18nKey]) => ({
    key,
    displayLabel: i18nKey
  }))
  const csvConfig = mkConfig({
    filename: `${i18n.t(`AssetTypeOptions.${assetType}`)} 
      ${formatDate(new Date(), `${preferences.dateFormat} HH:mm`, preferences.timeZone)}`,
    columnHeaders,
    useKeysAsHeaders: false
  })
  const csv = generateCsv(csvConfig)(csvData)
  download(csvConfig)(csv)
}

function handleExportAssetsV2Csv(data: ExportRowV2[], preferences: Preferences, assetType: AssetType) {
  const csvData = data.map((row) => ({
    ...row,
    personalRefNo: row.personalRefNo || '-',
    category: i18n.t(`AssetTypeOptions.${row.category}`),
    type: i18n.t(`${subtypeOptionMap[row.category]}${row.type}`, row.type),
    currentValuation: formatNumber(row.currentValuation, preferences.numberFormat),
    totalCost: formatNumber(row.totalCost || 0, preferences.numberFormat),
    lastUpdated: formatDate(row.lastUpdated, preferences.dateFormat, preferences.timeZone),
    purchaseDate: formatDate(row.purchaseDate, preferences.dateFormat, preferences.timeZone)
  }))
  const columnHeaders = Object.entries(assetTypeReportHeaderMapV2).map(([key, i18nKey]) => ({
    key,
    displayLabel: i18n.t(i18nKey)
  }))
  const csvConfig = mkConfig({
    filename: `${i18n.t(`AssetTypeOptions.${assetType}`)} 
      ${formatDate(new Date(), `${preferences.dateFormat} HH:mm`, preferences.timeZone)}`,
    columnHeaders,
    useKeysAsHeaders: false
  })
  const csv = generateCsv(csvConfig)(csvData)
  download(csvConfig)(csv)
}

function formatSummaryPDF(report: ComparativeNetWorthReport, preferences: Preferences) {
  const firstQuery = {
    original: i18n.t('CostAndLiabilities').toLocaleUpperCase(),
    current: i18n.t('Field.CurrentValuation').toLocaleUpperCase(),
    change: i18n.t('Change').toLocaleUpperCase()
  }
  const secondQuery = {
    original: [i18n.t('OriginalCost'), i18n.t('OriginalLiabilities'), i18n.t('NetInvestment')],
    current: [i18n.t('Assets'), i18n.t('Liabilities'), i18n.t('NetValue')],
    change: [i18n.t('Change'), `% ${i18n.t('Change')}`]
  }
  const rows = getSummaryRows(report, 'PDF', preferences.numberFormat)
  const headerClassNameMap: Record<ColumnType, string> = {
    blank: 'py-4',
    type: 'bg-asset-type-header font-bold',
    subtype: 'cell',
    total: 'font-bold',
    'new-page': ''
  }

  const newPageHeader = `
    </table>
    <div class="page-break"></div>
    <table class="hidden in-website-mode">
      <tr>
        <td class="align-start text-header" colspan="3">
          ${i18n.t('ReportGeneratedOn')} 
          (<span class="font-bold">${formatDate(new Date(), preferences.dateFormat, preferences.timeZone)}</span>)
        </td>
        ${Object.entries(firstQuery)
          .map(
            ([key, label], index) =>
              `<th class="bg-${key}-header text-header" colspan="${index === 2 ? 4 : 6}">${label}</th>`
          )
          .join('')}
      </tr>

      <tr>
        <td class="align-start text-header" colspan="3">
          ${i18n.t('ReportCurrency')} 
          (<span class="font-bold">${preferences.baseCurrency ?? Currency.USD}</span>)
        </td>
        ${Object.entries(secondQuery)
          .map(([key, labels]) =>
            labels.map((label) => `<th class="bg-${key}-header text-header" colspan="2">${label}</th>`).join('')
          )
          .join('')}
      </tr>`

  const CSSList = `
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto">
    <style type="text/css">
      table {
        table-layout: fixed;
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
        font-family: "Roboto", sans-serif;
      }
      td {
        text-align: end;
        border: 1px solid black;
        border-collapse: collapse;
        padding-top: 12px;
        padding-bottom: 12px;
      }
      th {
        background-color: grey;
        border: 1px solid black;
        border-collapse: collapse;
        padding-top: 12px;
        padding-bottom: 12px;
      }
      .align-start {
        text-align: start;
      }
      .text-white {
        color: #ffffff;
      }
      .text-red {
        color: red;
      }
      .font-bold {
        font-weight: 700;
      }
      .bg-background {
        background-color: #21253b;
      }
      .py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
      .pr-1 {
        padding-right: 4px;
      }
      .bg-original-header {
        background-color: #e1e1e1;
      }
      .bg-current-header {
        background-color: #d1d6e8;
      }
      .bg-change-header {
        background-color: #acb1c0;
      }
      .bg-asset-type-header {
        background-color: #efefef;
        border-bottom-color: #e1e1e1;
      }
      .text-header {
        font-size: 14px;
      }
      .bg-original-content {
        background-color: #f7f7f7;
      }
      .bg-current-content {
        background-color: #eef0f7;
      }
      .bg-change-content {
        background-color: #e4e6e9;
      }
      .text-content {
        font-size: 12px;
      }
      .cell {
        border-top-width: 0px;
        border-bottom-width: 0px;
      }
      .page-break { 
        break-before: always; 
      }
      @media print {
        .hidden-in-print-mode {
          display: none;
        }
        .in-website-mode {
          display: table !important;
        }
      }
      .hidden {
        display: none;
      }
    </style>`

  const getTable = (isPrintMode: boolean = false) => `
    <table class="${isPrintMode ? 'hidden in-website-mode' : 'hidden-in-print-mode'}">
      <tr>
        <th class="bg-background py-6" colspan="19">
          <img src="${origin}/images/myassets-logo.svg" width="220" height="30" />
        </th>
      </tr>

      <tr>
        <td class="align-start text-header" colspan="3">
          ${i18n.t('ReportGeneratedOn')}  
          (<span class="font-bold">${formatDate(new Date(), preferences.dateFormat, preferences.timeZone)}</span>)
        </td>
        ${Object.entries(firstQuery)
          .map(
            ([key, label], index) =>
              `<th class="bg-${key}-header text-header" colspan="${index === 2 ? 4 : 6}">${label}</th>`
          )
          .join('')}
      </tr>

      <tr>
        <td class="align-start text-header" colspan="3">${i18n.t('ReportCurrency')} (<span class="font-bold">${
          preferences.baseCurrency ?? Currency.USD
        }</span>)</td>
        ${Object.entries(secondQuery)
          .map(([key, labels]) =>
            labels.map((label) => `<th class="bg-${key}-header text-header" colspan="2">${label}</th>`).join('')
          )
          .join('')}
      </tr>

      ${Object.entries(rows)
        .map(([key, value], index) => {
          const header = isPrintMode && (index - 15) % 16 === 0 ? newPageHeader : ''
          const className = `align-start text-header ${headerClassNameMap[value.type]}`
          const colSpan = value.type === 'blank' ? 19 : 3
          const label = value.type === 'blank' ? '' : key
          const title = `${header}<td class="${className}" colspan="${colSpan}">${label}</td>`

          const columns =
            value.row.length > 0
              ? value.row
                  .map((col, index) => {
                    const cellColor =
                      value.type === 'type'
                        ? ''
                        : index > 5
                        ? 'bg-change-content'
                        : index > 2
                        ? 'bg-current-content'
                        : 'bg-original-content'
                    const isNegative = col.startsWith('(')
                    const columnClass = `
                      ${isNegative && 'text-red'} 
                      ${cellColor} 
                      ${headerClassNameMap[value.type]}
                      text-content pr-1`
                    return `<td class="${columnClass}" colspan="2">${col}</td>`
                  })
                  .join('')
              : ''

          return `<tr>${title}${columns}</tr>`
        })
        .join('')}
    </table>
  `
  return `${CSSList}\n${getTable(true)}\n${getTable(false)}`
}

function handleExportSummaryCsv(report: ComparativeNetWorthReport, preferences: Preferences) {
  const keys = [
    'type',
    'original-cost',
    'original-liabilities',
    'original-net-value',
    'current-cost',
    'current-liabilities',
    'current-net-value',
    'change',
    'change-percentage'
  ]
  const headers = [
    `${i18n.t('ReportGeneratedOn')} (${formatDate(new Date(), preferences.dateFormat, preferences.timeZone)})`,
    i18n.t('CostAndLiabilities').toLocaleUpperCase(),
    '',
    '',
    i18n.t('Field.CurrentValuation').toLocaleUpperCase(),
    '',
    '',
    i18n.t('Change').toLocaleUpperCase(),
    ''
  ]
  const subHeaders = [
    `${i18n.t('ReportCurrency')} (${preferences.baseCurrency ?? Currency.USD})`,
    i18n.t('OriginalCost'),
    i18n.t('OriginalLiabilities'),
    i18n.t('NetInvestment'),
    i18n.t('Assets'),
    i18n.t('Liabilities'),
    i18n.t('NetValue'),
    i18n.t('Change'),
    `% ${i18n.t('Change')}`
  ]
  const rows = getSummaryRows(report, 'CSV', preferences.numberFormat)
  const rowsWithSubHeader = {
    [`${i18n.t('ReportCurrency')} (${preferences.baseCurrency ?? Currency.USD})`]: {
      type: 'total',
      row: subHeaders.slice(1)
    },
    ...rows
  }
  const data = Object.entries(rowsWithSubHeader).map(([key, row]) => {
    if (row.type === 'blank') {
      return Object.fromEntries(keys.map((item) => [item, '']))
    }
    return Object.fromEntries(
      keys.map((item, index) => {
        if (index === 0) return [item, key]
        if (row.row.length === 0) return [item, '']
        const value = row.row[index - 1]
        const csvValue = value.startsWith('-') ? `(${value.slice(1)})` : value
        return [item, csvValue]
      })
    )
  })
  const columnHeaders = keys.map((key, index) => ({ key, displayLabel: headers[index] }))
  const csvConfig = mkConfig({
    filename: `Global Dashboard ${formatDate(new Date(), `${preferences.dateFormat} HH:mm`, preferences.timeZone)}`,
    columnHeaders,
    useKeysAsHeaders: false
  })
  const csv = generateCsv(csvConfig)(data)
  download(csvConfig)(csv)
}

function getSummaryRows(
  report: ComparativeNetWorthReport,
  fileType: 'CSV' | 'PDF' = 'PDF',
  numberFormat: NumberFormat = NumberFormat.Comma
): Record<string, { type: ColumnType; row: string[] }> {
  // Prevent from `- subtype` transformed into `#NAME?` by Microsoft Excel
  const parse = (v: string) => (fileType === 'CSV' ? `="${v}"` : v)

  const format = (value: number, isPercent: boolean = false) => {
    if (isNaN(value)) return ''
    return !isPercent
      ? formatNumber(value, numberFormat, { digits: 0 })
      : value > 0
      ? `${formatNumber(value, numberFormat)}%`
      : formatNumber(value, numberFormat).replace(')', '%)')
  }

  const getRow = (data?: NetWorthReportDetails) => {
    if (!data) return []
    return [
      ...Object.values(data.original).map((amount) => format(amount.value)),
      ...Object.values(data.current).map((amount) => format(amount.value)),
      format(data.netChange.amountChange.value),
      format(data.netChange.percentageChange, true)
    ]
  }

  const getCollectableRows = (
    assetType: AssetType.Art | AssetType.WineAndSpirits | AssetType.OtherCollectables
  ): Record<string, { type: ColumnType; row: string[] }> => {
    return {
      [i18n.t(`AssetTypeOptions.${assetType}`)]: {
        type: 'subtype',
        row: getRow(report.myCollectables.subtype[assetType])
      },
      ...Object.fromEntries(
        Object.entries(report.myCollectables.subtype[assetType].subtype)
          .sort((a, b) => b[1].current.netValue.value - a[1].current.netValue.value)
          .map(([key, value]) => [
            parse(`- ${i18n.t(`${subtypeOptionMap[assetType]}${key}`, key)}`),
            { type: 'subtype', row: getRow(value) }
          ])
      )
    }
  }

  const getAssetTypeRows = (
    title: keyof Omit<ComparativeNetWorthReport, 'total'>
  ): Record<string, { type: ColumnType; row: string[] }> => {
    const parseKey = (key: string) =>
      ({
        myFinances: parse(`- ${i18n.t(`AssetTypeOptions.${key}`, key)}`),
        myCollectables: '',
        myProperties: parse(`- ${key}`),
        myBelongings: parse(`- ${i18n.t(`${subtypeOptionMap.Belonging}${key}`, key)}`)
      })[title]

    switch (title) {
      case 'myFinances':
      case 'myBelongings':
        return {
          [i18n.t(title[0].toUpperCase() + title.slice(1))]: { type: 'type', row: getRow(report[title]) },
          ...Object.fromEntries(
            Object.entries(report[title].subtype)
              .sort((a, b) => b[1].current.netValue.value - a[1].current.netValue.value)
              .map(([key, value]) => [parseKey(key), { type: 'subtype', row: getRow(value) }])
          )
        }
      case 'myProperties':
        return {
          [i18n.t(title[0].toUpperCase() + title.slice(1))]: { type: 'type', row: getRow(report[title]) },
          ...Object.fromEntries(
            report[title].properties
              .sort((a, b) => b.current.netValue.value - a.current.netValue.value)
              .map((value) => [parseKey(value.name), { type: 'subtype', row: getRow(value) }])
          )
        }
      case 'myCollectables':
        const sortedKeys = Object.entries(report.myCollectables.subtype)
          .sort((a, b) => b[1].current.netValue.value - a[1].current.netValue.value)
          .map(([key, _]) => key) as (AssetType.Art | AssetType.WineAndSpirits | AssetType.OtherCollectables)[]
        return {
          [i18n.t(title[0].toUpperCase() + title.slice(1))]: { type: 'type', row: getRow(report[title]) },
          ...getCollectableRows(sortedKeys[0]),
          ...getCollectableRows(sortedKeys[1]),
          ...getCollectableRows(sortedKeys[2])
        }
    }
  }

  const sortedTabs = Object.entries(report)
    .filter(([key, _]) => key !== 'total')
    .sort((a, b) => b[1].current.netValue.value - a[1].current.netValue.value)
    .map(([key, _]) => key) as (keyof Omit<ComparativeNetWorthReport, 'total'>)[]

  return {
    [i18n.t('TotalNetWorth')]: { type: 'total', row: getRow(report.total) },
    blank1: { type: 'blank', row: [] as string[] },

    ...getAssetTypeRows(sortedTabs[0]),
    ...getAssetTypeRows(sortedTabs[1]),
    ...getAssetTypeRows(sortedTabs[2]),
    ...getAssetTypeRows(sortedTabs[3])
  }
}
